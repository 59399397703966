.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.cardCar {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.react-multi-carousel-item {
  width: inherit !important;
  display: flex;
  justify-content: center;
  margin-right: 66px;
}

.react-multi-carousel-item[aria-hidden="true"] {
  margin-right: 55px;
}

.react-multi-carousel-item:last-child {
  margin-right: 0px;
}

.react-multiple-carousel__arrow {
  z-index: 10 !important;
  top: calc(84% + 1px);
}

.react-multiple-carousel__arrow--left {
  left: calc(40% + 1px) !important
}

.react-multiple-carousel__arrow--right {
  right: calc(40% + 1px) !important
}

.react-multi-carousel-track {
  padding: 40px 0px !important;
  overflow-y: visible !important;
  overflow-x: unset !important;
  cursor: pointer;
}

#menu li a {
  display: block;
  color: white;
  text-align: left;
  text-decoration: none;
}

#menu ul {
  display: none;
  list-style-type: none;
  position: absolute;
  padding: 0;
}

.submenu {
  z-index: 50;
  width: 150px;
}

#twoSub {
  position: absolute;
  top: 56px;
  left: 900px;
}

.centerSub {
  right: 383px !important
}

.lastSub {
  right: 0;
}

.submenu li{
  background-color: white;
  text-decoration: none;
  margin-right: 0px !important;
}

.submenu li:last-child {
  margin-right: 0 !important;
}

.submenu a {
  padding: 12px;
  font-size: 15px !important;
  color: black !important;
}

.linkPort {
  margin: 0px auto;
  margin-top: 50px;
  margin-bottom: 80px;
}

.max {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1530px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: lightblue;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: black;
  display: flex;
  justify-content: center;
}

#root {
  max-width: 1530px;
}
