/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 7, 2023 */



@font-face {
    font-family: 'gotham_condensedmedium';
    src: url('/public/fonts/gothamcond-medium-webfont.eot');
    src: url('/public/fonts/gothamcond-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('/public/fonts/gothamcond-medium-webfont.woff2') format('woff2'),
         url('/public/fonts/gothamcond-medium-webfont.woff') format('woff'),
         url('/public/fonts/gothamcond-medium-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gothammedium';
    src: url('/public/fonts/gotham-medium_1-webfont.eot');
    src: url('/public/fonts/gotham-medium_1-webfont.eot?#iefix') format('embedded-opentype'),
         url('/public/fonts/gotham-medium_1-webfont.woff2') format('woff2'),
         url('/public/fonts/gotham-medium_1-webfont.woff') format('woff'),
         url('/public/fonts/gotham-medium_1-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gotham_condensedbook';
    src: url('/public/fonts/gothamcond-book-webfont.eot');
    src: url('/public/fonts/gothamcond-book-webfont.eot?#iefix') format('embedded-opentype'),
         url('/public/fonts/gothamcond-book-webfont.woff2') format('woff2'),
         url('/public/fonts/gothamcond-book-webfont.woff') format('woff'),
         url('/public/fonts/gothamcond-book-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}