.box {
    width: 290px;
    margin-right: 19.75px;
    margin-bottom: 19.75px;
    cursor: pointer;
}

.box:last-child {
    margin-right: 0px;
}

/* Ajusta el margen para el último elemento de cada fila */
.box:nth-child(5n) {
    margin-right: 0;
}

.box:nth-last-child(-n+5) {
    margin-bottom: 0;
}

.box img {
    border-radius: 10px;
}

.card {
    box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253) !important;
}

.card-text {
    font-size: .9rem;
    padding: 0.4rem
}

.container .row {
    padding-top: -8px;
    padding-bottom: 12px;
}